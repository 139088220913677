<template>
  <div class="row mt-4">
      <div class="col-12 ">
          <CardIcon name="ขนาดการติดตั้ง" :val="listData.sizeInstall?listData.sizeInstall:'-'" unit="" image="/images/icon/icon-solar.png" />
          <CardIcon :name="`วันที่ทำ Report`" :val="listData.date?listData.date:'-'" unit="" image="/images/icon/icon-schdule.png" />  
          <CardIcon :name="`ครั้งที่ทำความสะอาด / ทั้งหมด`" :val="listData.elec?listData.elec:'-'" unit="" image="/images/icon/icon-clean.png" />     
      </div>
  </div>
</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import CardIcon from '@/components/partial/CardIcon.vue';
export default {
  name: 'MonthlyDetail',
  components: {
    CardIcon
  },
  data: function () {
    return {
        listData:{
          sizeInstall:'999.00 kWp',
          date:'01-04-2021',
          elec:'1/2'
        }
        
    }
  },
  mounted() {
    //  this.getlistData()
  },
  methods: {
    async getlistData(){
      try {
        let data = {
          SIDE_ID:''
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetMainDashboard',data,'auth');
        this.listData = getAPI.data.data[0]
        // GASCO2: "1277.27"
        // LOCATION: "53/1/3 Moo 7 Suk Samat Road ,Tambon Na Pa Ampor Mueang Chonburi ,Changwa Thailand 20000"
        // PEAKPOW: "9.90"
        // PLANTNAME: "AJAWIT KALAWANTAVANICH"
        // TREES: "38.12258970"
        // UPD: "20210715 20:18"

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_dateYear:function(){
      return this.$store.getters['localstorage/GET_dateYear']
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
